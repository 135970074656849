<template>

    <a style="border: unset;" v-if="!isMobile && ready" @click="dialog = !dialog">{{ $t('a') }}</a> 

    <booking-dialog 
        v-if="dialog"
        @close="dialog = false">

        <template 
            #header
            v-if="$t(`${key}.title`)">{{ $t(`${key}.title`) }}</template>

        <template 
            #info
            v-if="view.info">{{ $t(view.info) }}</template>

        <template #content>
            <component 
                :is="view.component"
                :editable="isEditable"
                v-bind="view.bind"
                @next="next" />
        </template> 
    </booking-dialog>
</template>

<script>
import { ref, reactive, computed, onBeforeMount, onMounted, watch } from 'vue'
import i18n from '@/i18n'
import { loadLocale, setLocale } from '@/i18n'
import moment from 'moment'
import { loadYooMoneyCheckout } from '@/yoomoney'
import BookingDialog from '@/components/BookingDialog.vue'
import Welcome from '@/components/Welcome.vue'
import Booking from '@/components/Booking.vue'
import BookingDetails from '@/components/BookingDetails.vue'
import BookingPayment from '@/components/BookingPayment.vue'
import BookingPaymentCheckStatus from '@/components/BookingPaymentCheckStatus.vue'
import BookingSummary from '@/components/BookingSummary.vue'

import Waiting from '@/components/Waiting.vue'
import WaitingSummary from '@/components/WaitingSummary.vue'

const ready = ref(false)
const key = ref(Welcome.name)
const dialog = ref(false)

export const DATA = {
    token: null,
	guestNum: 1,
	time: null,
	date: moment().format('YYYY-MM-DD'),
	firstName: '',
	lastName: '',
	lastName: '',
	phone: '',
	email: '',
	restriction: '',
	status: null,
    payment: {
        number: null,
        date: null,
        master: null,
        cvc: null
    },
    // time: '17:45:00',
    // firstName: 'Test',
	// lastName: 'Test',
	// phone: '79262166306',
	// email: 'o.matyasov@italyvms.ru',
	// restriction: '...',
    // payment: {
    // //     number: '5555555555554444',
    //     number: '5555555555554477', //3DS
    //     date: '10/21',
    //     master: 'A A',
    //     cvc: '123'
    // }
    // payment: {
    //     number: '4274320055066867',
    //     date: '10/23',
    //     master: 'Matyasov Oleg',
    //     cvc: '167'
    // }
}

const data = reactive({ ...DATA, payment: { ...DATA.payment }})
const isEditable = computed(() => {
    let is = false
    switch(data.status) {
        case null:
        case 'temporary': 
            is = true
            break
    }
    return is
})
const paymentConf = reactive({
    shopId: null,
    amount: null
})
const paymentData = ref(null)

const infoEvent = reactive({
    type: null,
    message: null,
    set: (type, message) => {
        infoEvent.type = type
        infoEvent.message = message
    },
    clear: () => {
        infoEvent.type = null
        infoEvent.message = null
    }
})

const loading = reactive({
    is: false
})

const token = e => !!e
const firstName = e => e && e.length > 2
const lastName = e => e && e.length > 2
const phone = e => e && e.replace(/[^0-9]/g, '').match(/[0-9]{11}/)
const email = e => e && e.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
const guestNum = e => e && e > 0
const date = e => e && moment(e, "YYYY-MM-DD").isValid()
const time = e => e && moment(e, "HH:mm:ss").isValid()
const payment = e => {
    return e && e.number?.length > 6
        && e.date && /^\d\d\/\d\d$/.test(e.date) && moment(e.date, "MM/YY").isValid() && moment(e.date, "MM/YY").isSameOrAfter(moment(), 'month')
        && e.master?.length > 1 && !/[а-яёЁ]/i.test(e.master)
        && e.cvc?.length > 2
}

const STATE = {
    [Welcome.name]: {
        component: Welcome,
        bind: {
            booking: Booking.name,
            waiting: Waiting.name
        }
    },

    [Booking.name]: {
        component: Booking,
        bind: {
            data,
            waiting: Waiting.name,
            prev: Welcome.name,
            next: async () => {
                await post()
                return BookingDetails.name
            },
            isValid: null,
            loading
        },
        rules: {
            date,
            time,
            guestNum
        }
    },

    // [BookingDetails.name]: {
    //     component: BookingDetails,
    //     info: 'text.required',
    //     bind: {
    //         data,
    //         prev: Booking.name,
    //         next: async () => {
    //             loading.is = true
    //             try {
    //                 // await post('save')
    //                 // return BookingSummary.name
    //                 await post()
    //                 return BookingPayment.name
    //             } catch (e) {
    //                 console.log(e)
    //             } finally {
    //                 loading.is = false
    //             }                
    //         },
    //         isValid: null,
    //         loading
    //     },
    //     rules: {
    //         firstName,
    //         lastName,
    //         phone,
    //         email,
    //         guestNum
    //     }
    // },

    [BookingDetails.name]: {
        component: BookingDetails,
        info: 'text.required',
        bind: {
            data,
            prev: Booking.name,
            next: async () => {
                loading.is = true
                try {
                    await pay()
                    return paymentData.value?.status == 'waiting_for_capture' || paymentData.value?.status == 'succeeded'  
                        ? BookingSummary.name 
                        : null
                } catch (e) {
                    console.log(e)
                } finally {
                    loading.is = false
                }                
            },
            isValid: null,
            loading
        },
        rules: {
            firstName,
            lastName,
            phone,
            email,
            guestNum
        }
    },

    [BookingPayment.name]: {
        component: BookingPayment,
        info: 'text.required',
        bind: {
            data,
            next: async () => {         
                loading.is = true
                console.log('BookingPayment next...', paymentData.value?.status)
                try {
                    await pay()
                    return paymentData.value?.status == 'waiting_for_capture' || paymentData.value?.status == 'succeeded'  
                        ? BookingSummary.name 
                        : null
                } catch (e) {
                    console.log(e)
                } finally {
                    loading.is = false
                }
                },
            prev: BookingDetails.name,
            isValid: null,
            loading
        },
        rules: {
            token,
            payment
        }
    },

    [BookingSummary.name]: {
        component: BookingSummary,
        bind: {
            data,
            next: async () => {
                console.log('OK!')
                const path = window.location.pathname.split('/')
                if (path[1] && path[1] == '~booking') {
                    window.history.replaceState('', '', '/');
                }
                dialog.value = false
                return null
            },
            prev: null,
            loading
        }
    },
    
    [BookingPaymentCheckStatus.name]: {
        component: BookingPaymentCheckStatus,
        bind: {
            data,
            next: null,
            prev: null,
            loading
        }
    },

    [Waiting.name]: {
        component: Waiting,
        bind: {
            data,
            next: async () => {
                await post('save'); 
                return WaitingSummary.name
            },
            prev: Booking.name,
            loading
        },
        rules: {
            firstName,
            lastName,
            phone,
            email,
            guestNum
        }
    },

    [WaitingSummary.name]: {
        component: WaitingSummary,
        bind: {
            data,
            next: async () => {
                console.log('OK!')
                dialog.value = false
                return null
            },
            prev: null,
            loading
        }
    }
}

const dataToPost = computed(() => ({
    ...data,
    // date: key.value == Waiting.name ? null : data.date,
    time: key.value == Waiting.name ? null : moment(data.time, "HH:mm").format("HH:mm:ss"),
    phone: data.phone ? data.phone.replace(/[^0-9]/g, '') : ''
}))

const isValid = computed(() => STATE[key.value]?.rules 
        ? Object.keys(STATE[key.value].rules).every(e => STATE[key.value].rules[e](dataToPost.value[e]))
        : false
)

Object.keys(STATE).forEach(key => STATE[key].bind.isValid = isValid)

const pay = async () => {
    console.log('pay...')

    infoEvent.clear()

    if (paymentData.value?.status == 'waiting_for_capture' || paymentData.value?.status == 'succeeded' || data.token == null) return

    // if (paymentData.value?.status == 'pending') return await pay()
    
    try {

        // const checkout = YooMoneyCheckout(paymentConf.shopId, { language: 'en' })
        // const token = await checkout.tokenize({
        //     number: String(data.payment.number),
        //     cvc: 	String(data.payment.cvc),
        //     month: 	String(data.payment.date.split('/')[0]),
        //     year: 	String(data.payment.date.split('/')[1])
        // })

        // if (token.status === 'success') {
        //     const { paymentToken } = token.data.response

            const params = {
                // token: paymentToken
            }

            const response = await fetch(`/api/v1.0/payment/${dataToPost.value.token}`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(params)
            })            

            const pData = await response.json()

            if (response.status != 200) {
                infoEvent.set('error', pData.error)
                throw new Error(pData.error)
            }
            
            paymentData.value = pData

            if (paymentData.value.status == 'canceled') infoEvent.set('error', paymentData.value.cancellation_details)

            // paymentObject.value = pObj

            // console.log('createPayment:', {pObj})

            // await pay()

        // } else if (token.status === 'error') {
        //     console.log(token.error.type, token.error.params)
        //     // infoEvent.type = 'error'
        //     // infoEvent.message = token.error?.params[0]?.message
        //     infoEvent.set('error', token.error?.params[0]?.message)
        // }

    } catch(e) {
        console.log(e)
    }
    return false
}

const payStatus = async () => {

    // console.log('payStatus...', {data})

    if (data.token == null) return

    try {
        const response = await fetch(`/api/v1.0/payment/${data.token}`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const pData = await response.json()
        
        if (response.status != 200) throw new Error(pData.error)

        paymentData.value = pData        

        // console.log('pay:', {pData})
        
        // await pay()

    } catch (e) {
        console.log(e)
    } finally {
        // this.loading.is = false
    }
}

const post = async (type = null) => {

    if (!isValid.value) return;

    const url = [
        `/api/v1.0/booking`,
        type
    ].filter(f => f).join('/')


    try {
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify({ ...dataToPost.value, payment: null })
        })

        if (response.status !== 200) return;

        const reData = await response.json()
        
        if (!data.token && reData.token) data.token = reData.token

        data.status = reData.status

    } catch (e) {
        console.log(e)
    } 
}

const get = async (token) => {

    const url = [
        `/api/v1.0/booking`,
        token
    ].filter(f => f).join('/')


    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        })

        if (response.status !== 200) return;

        const reData = await response.json()

        console.log({reData})

        Object.keys(reData).forEach(e => data[e] = reData[e])
        
        // if (!data.token && reData.token) data.token = reData.token

        // data.status = reData.status

    } catch (e) {
        console.log(e)
    } 
}

const init = async () => {
    const path = window.location.pathname.split('/')
    if (path[1] && path[1] == '~booking' && path[2]) {
        await get(path[2])

        if (data.token) {
            console.log({data})
            await payStatus()
            dialog.value = true
        }

        // window.history.replaceState('', '', '/')     
    }
}

export {
    init,
    paymentConf,
    payStatus,
    paymentData,
    infoEvent
}

export default {
    name: 'App',

    components: { 
        BookingDialog, 
        Welcome,
        Booking,
        BookingDetails,
        BookingPayment,
        BookingSummary,
        Waiting,
        WaitingSummary
    },

    setup() {

        // let { t, locale } = useI18n()
        const locale = ref(null)
        const view = computed(() => STATE[key.value])
        let postTimout = null
        const isMobile = ref(false)

        const next = async e => {
            if (typeof e == "function") {
                e = await e()
            }
            if (e) key.value = e
        }

        const checkKey = () => {
            console.warn('checkKey', data)
            if (paymentData.value) {
                switch (paymentData.value.status) {
                    case 'canceled': 
                        key.value = BookingPayment.name
                        infoEvent.set('info', paymentData.value.cancellation_details)
                        break
                    case 'succeeded': 
                        key.value = BookingSummary.name
                     break
                    case 'pending': 
                        switch(paymentData.value.confirmation?.type) {
                            case 'redirect':
                                document.location = paymentData.value.confirmation.confirmation_url
                            break
                            default:
                                console.log('Status PENDING...')
                                key.value = BookingPaymentCheckStatus.name
                        }
                        break
                }
            } else if(data) {
                console.warn(data.status)
                switch (data.status) {
                    case 'confirmed':
                        key.value = BookingPayment.name
                        break
                }
            }
        }

        onBeforeMount(async () => {
            locale.value = window.location.pathname.match(/^\/ru/) ? 'ru' : 'en'

            await loadLocale(locale.value)
            setLocale(locale.value)

            loadYooMoneyCheckout()

            try {
                const response = await fetch(`/api/v1.0/payment/conf`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                
                const conf = await response.json()
                
                if (response.status != 200) throw new Error(conf.error)

                console.log({conf})

                paymentConf.shopId = conf.shopId
                paymentConf.amount = conf.amount           

            } catch (e) {
                console.log(e)
            } finally {
                // this.loading.is = false
            }

            ready.value = true

        })

        onMounted(() => {

            const mobile_menu = document.querySelector('#__booking__mobile__')

            if (mobile_menu) {
                console.log({mobile_menu}, mobile_menu.offsetParent,  !(window.getComputedStyle(mobile_menu).display == "none"))
                isMobile.value = !(window.getComputedStyle(mobile_menu).display == "none")
                mobile_menu.addEventListener('click', () => dialog.value = true)
            }            

            window.onpopstate = e => {
                // alert(e)
                // console.log({e}, $('.menu').hasClass('active'))
                if (isMobile.value && dialog.value) {
                    dialog.value = false
                }
            }
        })

        watch(() => data, () => {
            
            if ([Booking, BookingDetails, Waiting].every(s => s.name != key.value)) return

            if (postTimout) clearTimeout(postTimout)

            postTimout = setTimeout(() => post(), 750)
            
        }, { deep: true })

        watch(dialog, dialog => {
            console.log({dialog, key: key.value + ''})
            if (dialog) {
                // locale.value = window.location.pathname.match(/^\/ru/) ? 'ru' : 'en'
                checkKey()
                console.log({key: key.value + ''})
            } else {
                Object.keys(data).forEach(e => data[e] = DATA[e])
                paymentData.value = null
                key.value = Welcome.name
            }
        })

        watch(paymentData, paymentData => {
            console.warn({paymentData, data})
            checkKey()
        }, { 
            deep: true,
            immediate: true
        })

        // watch(dialog, dialog => {
        //     console.log({dialog})
        //     if (!dialog) {
        //         // const newData = { ...DATA, payment: { ...DATA.payment }}
        //         Object.keys(DATA).forEach(e => data[e] = DATA[e])
        //         Object.keys(DATA.payment).forEach(e => data.payment[e] = DATA.payment[e])
        //         paymentData.value = null
        //     }
        // })

        return { 
            ready,
            isMobile,
            dialog,
            key,
            view,
            next,
            data,
            isEditable,
            isValid,
            dataToPost,
            paymentConf,
            paymentData,
            loading,
            DATA
        }
    }
}
</script>

<style lang="scss">
@import 'assets/booking.scss';

</style>
