import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    locale: 'en', 
    fallbackLocale: 'en'
})

console.log({i18n})

export const loadLocale = async lang => {
    const localeRes = await fetch(`/~locale/${lang}/booking.json`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const tr = await localeRes.json()
    i18n.global.setLocaleMessage(lang, tr)
}

export const setLocale = lang => {
    i18n.global.locale = lang
}

export default i18n